import type { UtilityContext } from '@change/core/react/utilityContext';

import { FCM_SUBSCRIPTION_CONFIGURATION } from 'src/app/shared/fcm/configs';

type SubscriptionAmount = {
	amount: number;
	amountLocalized: string;
};

type Result = {
	amounts: SubscriptionAmount[];
	minAmount: number;
	maxAmount: number;
	defaultAmount: SubscriptionAmount;
	thirdPartySubscriptionService: string | undefined;
	subscriptionCancellationSurveyId: string | undefined;
	disableFeedbackForm: boolean | undefined;
};

function mapAmountToSubscriptionAmount(
	amount: number,
	currency: string,
	i18n: UtilityContext['i18n'],
): SubscriptionAmount {
	const { amountFromBaseUnits, localizeCurrency } = i18n;
	const amountInCurrency = amountFromBaseUnits(amount, currency);
	return {
		amount: amountInCurrency,
		amountLocalized: localizeCurrency(amountInCurrency, currency, {
			currencyDisplay: 'narrowSymbol',
			amountInBaseUnits: false,
		}),
	};
}

export async function getSubscriptionConfig(
	utilityContext: UtilityContext,
	currency: string,
): Promise<Result | undefined> {
	const {
		fcm,
		i18n: { amountFromBaseUnits },
	} = utilityContext;
	const { subscriptionConfig } = await fcm.get({
		subscriptionConfig: FCM_SUBSCRIPTION_CONFIGURATION,
	});

	if (!subscriptionConfig) {
		return undefined;
	}

	const subscriptionsAmounts = subscriptionConfig.amounts.map((amount) =>
		mapAmountToSubscriptionAmount(amount, currency, utilityContext.i18n),
	);

	const subscriptionsDefaultAmount = mapAmountToSubscriptionAmount(
		subscriptionConfig.defaultAmount,
		currency,
		utilityContext.i18n,
	);

	return {
		amounts: subscriptionsAmounts,
		defaultAmount: subscriptionsDefaultAmount,
		minAmount: amountFromBaseUnits(subscriptionConfig.minAmount, currency),
		maxAmount: amountFromBaseUnits(subscriptionConfig.maxAmount, currency),
		thirdPartySubscriptionService: subscriptionConfig.thirdPartySubscriptionService,
		subscriptionCancellationSurveyId: subscriptionConfig.subscriptionCancellationSurveyId,
		disableFeedbackForm: subscriptionConfig.disableFeedbackForm,
	};
}

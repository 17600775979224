import type { TranslationKey } from '@change/core/i18n';
import { translationKey } from '@change/core/i18n';

type AmountValidationArgs = {
	amount: number;
	minimumAmount?: number | null;
	maximumAmount?: number | null;
};

export function validateAmount({ amount, minimumAmount, maximumAmount }: AmountValidationArgs): TranslationKey | null {
	if (amount < 1) return translationKey('corgi.co.promote.validation.amount.not_present');
	if (minimumAmount && amount < minimumAmount) return translationKey('corgi.co.promote.validation.amount.below_min');
	if (maximumAmount && amount > maximumAmount) return translationKey('corgi.co.promote.validation.amount.above_max');

	return null;
}

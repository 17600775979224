import type { JSX } from 'react';

import { Radio } from '@change/design-system/components/forms';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { HighlightText } from './HighlightText';
import { Label } from './Label';

type Props = {
	amount?: string;
	highlight?: string;
	label: string;
	message: string;
	onSelect: () => void;
	checked: boolean;
	qaSelectorSelection: string;
	qaSelectorLabel: string;
	qaSelectorMessage: string;
};

export function Selection({
	qaSelectorSelection,
	qaSelectorLabel,
	qaSelectorMessage,
	label,
	message,
	amount,
	highlight,
	onSelect,
	checked,
}: Props): JSX.Element {
	return (
		<Box sx={{ mt: 16, mb: 5 }}>
			{highlight && <HighlightText text={highlight} selected={checked} />}
			<Box
				variant="bordered"
				sx={{
					p: 10,
					...(highlight
						? { borderRadius: 'standard', borderTopLeftRadius: 0, borderTopRightRadius: 0 }
						: { borderRadius: 'standard' }),
					...(checked ? { borderWidth: 2 } : {}),
					borderColor: checked ? 'secondary-blue' : 'neutral-grey400',
				}}
				onClick={onSelect}
			>
				<Radio
					data-qa={qaSelectorSelection}
					data-testid="selection-radio"
					checked={checked}
					readOnly
					label={
						<Box>
							<Label qaSelectorLabel={qaSelectorLabel} amount={amount} label={label} />
							<Text data-qa={qaSelectorMessage} size="small">
								{message}
							</Text>
						</Box>
					}
				/>
			</Box>
		</Box>
	);
}

import { useStripe } from '@stripe/react-stripe-js';

import type { PaymentMethod } from 'src/app/shared/utils/payments';
import { getPaymentConfirmationHandler } from 'src/app/shared/utils/payments';

import { useLoadStripe } from '../useLoadStripe';

export type StripeGatewayData = {
	readonly clientSecret: string | null;
	readonly accountId: string | null;
	readonly paymentMethodId: string | null;
};

export function useStripeConfirm(): (
	chargeCreationResult: StripeGatewayData | undefined,
	paymentMethod: PaymentMethod,
) => Promise<void> {
	const initialStripeInstance = useStripe();
	const loadStripe = useLoadStripe();

	return async function confirm(chargeCreationResult, paymentMethod) {
		if (!chargeCreationResult?.clientSecret) return;

		const stripe = await loadStripe(initialStripeInstance, chargeCreationResult.accountId);
		const handler = getPaymentConfirmationHandler(paymentMethod.type);

		const confirmError = await handler(stripe, undefined, chargeCreationResult.clientSecret);

		if (confirmError) throw confirmError;
	};
}

import { useI18n } from '@change/core/react/i18n';

import { useMembershipModalState } from '../../../modalContext';
import type { ModalCallbackProps } from '../../../shared/types';
import { useSubmit } from '../useSubmit';

type Result = ModelHookResult<
	{
		amount: string;
		acceptMembership: boolean;
		suffix: string;
		qaSuffix: string;
		startDate: string;
	},
	{
		onSelectYes: () => void;
		onSelectNo: () => void;
		onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
	}
>;

export function useMembershipForm({ onAccept, onDecline }: ModalCallbackProps): Result {
	const submit = useSubmit({ onAccept, onDecline });

	const [{ acceptMembership, amount, currencyCode, useDonationLanguage }, { setAcceptMembership }] =
		useMembershipModalState();

	const { localizeCurrency, localizeDate } = useI18n();

	return {
		data: {
			acceptMembership,
			amount: localizeCurrency(amount, currencyCode),
			suffix: useDonationLanguage ? '_donation' : '',
			qaSuffix: useDonationLanguage ? '-donation' : '',
			startDate: localizeDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), 'date', 'long'),
		},
		actions: {
			onSelectYes: () => setAcceptMembership(true),
			onSelectNo: () => setAcceptMembership(false),
			onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
				event.preventDefault();
				await submit();
			},
		},
	};
}

import type { Environment } from '@change/core/environment';
import type { ReportableError } from '@change/core/errorReporter/common';
import { isSsr } from '@change/core/ssr';

// TODO move to fe-config
import dLocalConfig from 'config/dlocal.json';

import { addScriptWithErrorReporting } from 'src/app/shared/utils/dom';

export async function loadDLocalScript(
	reportError: (error: ReportableError) => Promise<{
		url?: string | undefined;
	}>,
	environment: Environment,
): Promise<unknown> {
	if (isSsr()) throw new Error('Dlocal SDK cannot be used with SSR');

	return addScriptWithErrorReporting(dLocalConfig.url[environment], reportError);
}

import { GQLResponsePerimeterXChallengeError } from '@change/core/gql';
import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';

import type { PaymentType } from 'src/app/shared/utils/payments';

import { useMembershipModalState } from '../../modalContext';
import type { ModalCallbackProps } from '../../shared/types';

export function useOnTokenError({ onDecline }: ModalCallbackProps): (paymentType: PaymentType, err: Error) => void {
	const [, { setGeneralErrorMessage, clearLoading }] = useMembershipModalState();
	const { translate } = useI18n();
	const track = useTracking();

	return function onTokenError(_paymentType, err): void {
		clearLoading();
		if (err instanceof GQLResponsePerimeterXChallengeError) {
			void track('post_promote_member_failure', {
				source_location: 'post_promote',
				errorMessage: 'Human verification required',
				errorType: 'RequestBlocked',
			});
			onDecline();
		} else {
			void track('post_promote_member_failure', {
				errorMessage: err.message || 'Unknown error',
			});
		}
		setGeneralErrorMessage(translate('corgi.co.promote.generic_payment_error'));
	};
}

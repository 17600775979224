import type { UtilityContext } from '@change/core/react/utilityContext';

import type { PaymentMethodUsage } from '@change-corgi/middle-end/gql';

import type { DLocalCard } from 'src/app/shared/api/payments';
import { setupPaymentMethod } from 'src/app/shared/api/payments';

export type DLocalPaymentSetupParams = {
	token: string;
	name: string;
	usage: PaymentMethodUsage;
	countryCode: string;
	document: string | null;
};

export async function setupDLocalPaymentMethod(
	{ token, usage, countryCode, name, document }: DLocalPaymentSetupParams,
	utilityContext: UtilityContext,
): Promise<DLocalCard> {
	const setupPaymentMethodResp = await setupPaymentMethod({
		input: {
			gateway: 'DLOCAL',
			usage,
			token,
			customer: {
				countryCode,
				currencyCode: null,
				billingName: name,
			},
			type: 'CREDIT_CARD',
			dlocal: document ? { document } : null,
		},
		utilityContext,
	});

	if (!setupPaymentMethodResp?.gatewayData) throw new Error('Error retrieving dLocal card setup');
	if (setupPaymentMethodResp.gatewayData.__typename !== 'SetupPaymentMethodDLocalGatewayData')
		throw new Error('Error retrieving dLocal card setup');

	const dlocalGatewayData = setupPaymentMethodResp.gatewayData;

	if (!dlocalGatewayData.card) throw new Error('Error retrieving dLocal card setup');

	return dlocalGatewayData.card;
}

// TODO: this component should be split up to separate concerns
// (display component for the fields, functions or hooks for the tokenization process)

import React, { cloneElement } from 'react';

import { useStripe } from '@stripe/react-stripe-js';

import { FormError } from '@change/design-system/components/forms';
import { Box, Flex } from '@change/design-system/layout';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import type { Props as SubmitButtonProps } from '../../../components/SubmitButton';
import { StripeCreditCardElements } from '../../components/StripeCreditCardElements';

import { useStripeForm } from './hooks/useStripeForm';

export type StripePaymentDetails = {
	paymentMethod: {
		token: string;
	};
};

type Props = {
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	email: string;
	name: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	submitButton: React.ReactElement<SubmitButtonProps>;
	countryStateSelect?: React.JSX.Element | null;
	phoneNumberInput?: React.JSX.Element | null;
	transactionFeeToggle?: React.JSX.Element | null;
	onCardNumberFieldFocus: () => void;
	onCardExpiryFieldFocus: () => void;
	onCardCVCFieldFocus: () => void;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
};

export function StripeForm({
	beforeToken,
	email,
	name,
	paymentMethodSaveOptions,
	submitButton,
	countryStateSelect,
	phoneNumberInput,
	transactionFeeToggle,
	onCardNumberFieldFocus,
	onCardExpiryFieldFocus,
	onCardCVCFieldFocus,
	onTokenError,
	onTokenInvalid,
	validate,
	withToken,
	prePaymentChecks,
}: Props): React.JSX.Element {
	const stripe = useStripe();

	const {
		data: { errorMessage, validationErrors },
		actions: { handleSubmit, validateStripeElementOnChange },
	} = useStripeForm({
		beforeToken,
		email,
		name,
		paymentMethodSaveOptions,
		onTokenError,
		onTokenInvalid,
		validate,
		withToken,
		prePaymentChecks,
	});

	return (
		<>
			<Box mb={16}>
				<form data-testid="stripe-form" onSubmit={handleSubmit}>
					<Box mb={24}>
						<StripeCreditCardElements
							validationErrors={validationErrors}
							onCardNumberFieldFocus={onCardNumberFieldFocus}
							onCardExpiryFieldFocus={onCardExpiryFieldFocus}
							onCardCVCFieldFocus={onCardCVCFieldFocus}
							onChange={validateStripeElementOnChange}
						/>
						{countryStateSelect}
					</Box>

					{countryStateSelect}
					{phoneNumberInput}
					{transactionFeeToggle}
					<Flex sx={{ justifyContent: 'center' }}>
						{cloneElement(submitButton, {
							disabled: submitButton.props.disabled || !stripe,
							// note that this will only work if submitButton supports a loading property
							loading: submitButton.props.loading,
						})}
					</Flex>
				</form>
			</Box>
			{errorMessage && (
				<FormError mb={16} data-qa="stripe-form-error-message">
					{errorMessage}
				</FormError>
			)}
		</>
	);
}

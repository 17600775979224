import { TrackingContext } from '@change/core/react/tracking';
import { Box, Container } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';

import { StripeLoader } from 'src/app/shared/components/payments';
import { PaypalProvider } from 'src/app/shared/hooks/payments';

import { Footer } from './components/Footer';
import { GeneralErrorMessage } from './components/GeneralErrorMessage';
import { Header } from './components/Header';
import { MembershipForm } from './container/MembershipForm';
import { MembershipModalStateContextProvider, useMembershipModalState } from './modalContext/modalState';
import type { ModalOptions, TrackingProperties } from './shared/types';

type Options = ModalOptions & { properties: TrackingProperties };

export const useMembershipAskModal = createModalHook<Options, { accepted: boolean; amount: number }>({
	name: 'MembershipModal',
	closeButton: false,
	closeOnEscape: false,
	closeOnClickOutside: false,
	variant: 'fullPage',
	body: function MembershipModalBody({ closeModal }) {
		const [{ amount }] = useMembershipModalState();
		return (
			<Container sx={{ pt: ['0%', '5%', '5%'] }}>
				<Box sx={{ m: 'auto', px: [0, 32], width: ['100%', '80%', '50%'] }}>
					<Header />
					<MembershipForm
						onAccept={() => closeModal({ accepted: true, amount })}
						onDecline={() => closeModal({ accepted: false, amount })}
					/>
					<GeneralErrorMessage />
					<Footer />
				</Box>
			</Container>
		);
	},
	decorator: ({ children, options: { properties, ...modalState } }) => (
		<MembershipModalStateContextProvider {...modalState}>
			<TrackingContext properties={properties}>
				<StripeLoader>
					<PaypalProvider>{children}</PaypalProvider>
				</StripeLoader>
			</TrackingContext>
		</MembershipModalStateContextProvider>
	),
});

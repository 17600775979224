import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { usePaymentTokenFunctions } from '../../../../hooks/usePaymentTokenFunctions';
import { useMembershipModalState } from '../../../../modalContext';
import type { ModalCallbackProps } from '../../../../shared/types';

type Result = ModelHookResult<
	{
		amount?: number;
		billingAgreementDescription?: string;
		currency: string;
		disabled: boolean;
		email: string;
		flow: paypal.FlowType;
		loading: boolean;
		paymentMethodSaveOptions: PaymentMethodSaveOptions;
	},
	{
		beforeToken: (paymentType: PaymentType) => Promise<boolean>;
		onTokenError: (paymentType: PaymentType, err: Error) => void;
		onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
		validate: (paymentType: PaymentType) => Promise<boolean>;
		withToken: (params: WithTokenParams) => Promise<void>;
		trackLoadStartTime: () => void;
		trackLoadEndTime: (duration: number) => void;
	}
>;

export function useNewPaymentMethodPaypal(modalCallbackProps: ModalCallbackProps): Result {
	const { translate } = useI18n();

	const track = useTracking();

	const [{ currencyCode, formState, email, useDonationLanguage }] = useMembershipModalState();

	const { beforeToken, onTokenError, onTokenInvalid, withToken } = usePaymentTokenFunctions(modalCallbackProps);

	const data: Result['data'] = {
		currency: currencyCode,
		disabled: formState?.status === 'loading',
		email,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		flow: 'vault' as paypal.FlowType.Vault,
		loading: formState?.status === 'loading',
		billingAgreementDescription: useDonationLanguage
			? translate('corgi.payments.paypal_billing_agreement_description_donation')
			: translate('corgi.payments.paypal_billing_agreement_description'),
		paymentMethodSaveOptions: { shouldSavePaymentMethod: true, usage: 'OFF_SESSION' },
	};

	return {
		data,
		actions: {
			beforeToken,
			onTokenError,
			onTokenInvalid: (paymentType: PaymentType, err: Error) => {
				void track('paypal_auth_failure');
				return onTokenInvalid(paymentType, err);
			},
			validate: async (_paymentType: PaymentType) => {
				void track('paypal_login_view');
				return Promise.resolve(true);
			},
			withToken: async ({ paymentType, token }: WithTokenParams) => {
				void track('paypal_auth_success');
				return withToken({ paymentType, token });
			},
			trackLoadStartTime: () => {
				void track('ppmu_paypal_button_loading_start_time');
			},
			trackLoadEndTime: (duration: number) => {
				void track('ppmu_paypal_button_loading_end_time', { duration });
			},
		},
	};
}

import { useI18n } from '@change/core/react/i18n';

import type { WithTokenParams } from 'src/app/shared/types/index.js';
import type { PaymentType } from 'src/app/shared/utils/payments';

import type { NewStripePaymentRequestButtonProps } from '../../index.tsx';

import { useTracking } from './useTracking';

type Result = ModelHookResult<
	{
		amount: number;
		currencyCode: string;
		label: string;
		paymentType?: 'applePay' | 'googlePay';
		testId: string;
	},
	{
		beforeToken: (paymentType: PaymentType) => Promise<boolean>;
		onCancel: () => void;
		validate: (paymentType: PaymentType) => Promise<boolean>;
		withToken: (params: WithTokenParams) => Promise<void>;
		prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	}
>;

export function useNewStripePaymentRequestButton({
	currencyCode,
	ask,
	amount,
	selectedPaymentType,
	clearLoading,
	beforeToken,
	withToken,
	validate,
	prePaymentChecks,
}: NewStripePaymentRequestButtonProps): Result {
	const { amountToBaseUnits, translate } = useI18n();
	const { trackPaymentRequestCancel } = useTracking();

	const isSupportedPaymentType = selectedPaymentType === 'applePay' || selectedPaymentType === 'googlePay';

	return {
		data: {
			amount: amountToBaseUnits(amount, currencyCode),
			currencyCode,
			label: translate(
				'fe.components.payment_request_button.line_item_description.promotion', // TODO: move this i18n key to corgi namespace
				{ petition_title: ask },
			),
			paymentType: isSupportedPaymentType ? selectedPaymentType : undefined,
			testId: selectedPaymentType === 'applePay' ? 'apple-pay-button' : 'google-pay-button',
		},
		actions: {
			beforeToken,
			onCancel: () => {
				clearLoading();
				trackPaymentRequestCancel();
			},
			validate,
			withToken,
			prePaymentChecks,
		},
	};
}

import type { ReportableError } from '@change/core/errorReporter/common';

import { addScriptWithErrorReporting } from 'src/app/shared/utils/dom';

export async function loadPaypalScripts(
	reportError: (error: ReportableError) => Promise<{
		url?: string | undefined;
	}>,
): Promise<[unknown, unknown, unknown]> {
	return Promise.all([
		addScriptWithErrorReporting('https://js.braintreegateway.com/web/3.109.0/js/client.min.js', reportError),
		addScriptWithErrorReporting('https://js.braintreegateway.com/web/3.109.0/js/paypal-checkout.min.js', reportError),
		addScriptWithErrorReporting('https://js.braintreegateway.com/web/3.109.0/js/data-collector.min.js', reportError),
	]);
}

import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Box } from '@change/design-system/layout';

type AmountInputProps = {
	currencySymbol: string;
	currencySymbolPosition: 'before' | 'after';
	decimalSeparator: string;
	errorMessage: string | null;
	onChange: (value: number) => void;
	onFocus: () => void;
	showFractionNumber: boolean;
	value: number;
	isCustomAmount: boolean;
};

// eslint-disable-next-line max-lines-per-function
export function AmountInput({
	currencySymbol,
	currencySymbolPosition,
	decimalSeparator,
	value,
	showFractionNumber,
	onChange,
	onFocus,
	errorMessage,
	isCustomAmount,
}: AmountInputProps): React.JSX.Element {
	const inputRef = useRef<HTMLInputElement>(null);
	const lengthLimitToPreventScientificNotation = 12;

	useEffect(() => {
		if (inputRef.current && errorMessage) inputRef.current.focus();
	}, [errorMessage]);

	useEffect(() => {
		if (inputRef.current && isCustomAmount) inputRef.current.focus();
	}, [isCustomAmount]);

	const handleChangeEvent = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			const newValue = event.target.value;
			const decimalRegex = /[.|,]/;
			if (decimalRegex.test(newValue.toString())) {
				// decimal character was entered
				// trackEvent('amount_input_decimal_entered', { character: newAmount.match(decimalRegex)[0] });
			}
			if (/^\d*$/.test(newValue.toString())) {
				onChange(Number(newValue));
			}
		},
		[onChange],
	);

	const { valueWithoutDecimal, decimalValue } = useMemo(() => {
		const [integerPart, decimalPart] = value.toFixed(2).split('.');
		return {
			valueWithoutDecimal: Number.parseInt(integerPart, 10),
			decimalValue: decimalPart,
		};
	}, [value]);

	const colorStyles = useMemo(() => {
		if (value)
			return {
				color: 'secondary-blue',
				backgroundColor: 'rgba(21, 125, 185, 0.08)',
				borderColor: errorMessage ? 'status-critical500' : 'secondary-blue',
			};
		return {
			color: 'typography-primary',
			backgroundColor: 'primary-white',
			borderColor: 'neutral-grey100',
		};
	}, [value, errorMessage]);

	return (
		<Box
			variant="bordered"
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				px: 24,
				height: 48,
				borderWidth: value ? '2px' : '1px',
				borderRadius: '8px',
				...colorStyles,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:focus-within': {
					color: 'secondary-blue',
					backgroundColor: 'rgba(21, 125, 185, 0.08)',
					borderColor: errorMessage ? 'status-critical500' : 'secondary-blue',
				},
			}}
			data-testid="payment-form-amount-input-container"
		>
			{currencySymbolPosition === 'before' && (
				<label
					htmlFor="amount"
					sx={{
						fontSize: '14px',
						fontWeight: 'bold',
					}}
				>
					{currencySymbol}
				</label>
			)}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					flexGrow: 1,
				}}
			>
				<input
					name="amount"
					data-testid="payment-form-amount-input"
					data-qa="custom-amount-input"
					value={value > 0 ? valueWithoutDecimal : ''}
					autoComplete="off"
					onChange={handleChangeEvent}
					onFocus={onFocus}
					type="text"
					maxLength={lengthLimitToPreventScientificNotation}
					pattern="[0-9]*" /* this is here to make the numeric keypad appear on mobile devices */
					ref={inputRef}
					aria-label="amount" /* TODO: replace with i18n label */
					sx={{
						backgroundColor: 'inherit',
						color: 'inherit',
						width: '100%',
						border: 'none',
						outline: 'none',
						fontSize: '16px',
						fontWeight: 'bold',
						textAlign: 'right',
						py: 8,
						px: 0,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:focus': {
							outline: 'none',
						},
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&::-ms-clear': {
							display: 'none',
						},
					}}
				/>
			</Box>
			{value > 0 && showFractionNumber && (
				<label
					htmlFor="amount"
					sx={{
						fontSize: '16px',
						fontWeight: 'bold',
					}}
				>
					{decimalSeparator}
					{decimalValue}
				</label>
			)}
			{currencySymbolPosition === 'after' && (
				<label
					htmlFor="amount"
					sx={{
						fontSize: '14px',
						fontWeight: 'bold',
					}}
				>
					{currencySymbol}
				</label>
			)}
		</Box>
	);
}

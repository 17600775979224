import { useEffect } from 'react';
import type { JSX } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';
import { Box } from '@change/design-system/layout';

import { ContinueButton } from '../components/ContinueButton';
import { NewPaymentMethodPaypal } from '../components/NewPaymentMethodPaypal';
import { Selection } from '../components/Selection';
import { StripePaymentRequestButton } from '../components/StripePaymentRequestButton';
import type { ModalCallbackProps } from '../shared/types';

import { useAmountUpdateListener } from './hooks/useAmountUpdateListener';
import { useMembershipForm } from './hooks/useMembershipForm';
import { useUserEventListeners } from './hooks/useUserEventListeners';

export function MembershipForm({ onAccept, onDecline }: ModalCallbackProps): JSX.Element {
	const { translate } = useI18n();
	const {
		actions: { onSelectNo, onSelectYes, onSubmit },
		data: { acceptMembership, amount, qaSuffix, suffix, startDate },
	} = useMembershipForm({ onAccept, onDecline });

	useUserEventListeners({ onAccept, onDecline });

	useAmountUpdateListener();

	const track = useTracking();

	useEffect(() => {
		void track('post_promote_membership_ask_modal_view');
		// This will only run when the component is mounted
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<form onSubmit={onSubmit} sx={{ mt: 24 }}>
			<Selection
				qaSelectorSelection="ppmu-modal-selection-true"
				qaSelectorLabel={`ppmu-modal-selection-yes-title${qaSuffix}`}
				qaSelectorMessage={`ppmu-modal-selection-yes-description${qaSuffix}`}
				label={translate(`corgi.co.ppmu.modal.yes_title${suffix}`)}
				message={translate(`corgi.co.ppmu.modal.yes_description${suffix}`, { startDate })}
				amount={translate('corgi.co.ppmu.modal.contribution_text', { amount })}
				highlight={translate('corgi.co.ppmu.modal.highlight_text')}
				checked={acceptMembership}
				onSelect={onSelectYes}
			/>
			<Selection
				qaSelectorSelection="ppmu-modal-selection-false"
				qaSelectorLabel="ppmu-modal-selection-no-title"
				qaSelectorMessage={`ppmu-modal-selection-no-description${qaSuffix}`}
				label={translate('corgi.co.ppmu.modal.no_title')}
				message={translate(`corgi.co.ppmu.modal.no_description${suffix}`)}
				checked={!acceptMembership}
				onSelect={onSelectNo}
			/>
			<Box sx={{ mt: 24, mb: 8 }}>
				<ContinueButton />
				<NewPaymentMethodPaypal onAccept={onAccept} onDecline={onDecline} />
				<StripePaymentRequestButton onAccept={onAccept} onDecline={onDecline} />
			</Box>
		</form>
	);
}

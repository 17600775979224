import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	PaymentsSharedSetupPaymentDLocalFieldsFragment,
	PaymentsSharedSetupPaymentMethodMutation,
	PaymentsSharedSetupPaymentMethodMutationVariables,
	PaymentsSharedSetupPaymentMethodSuccessFieldsFragment,
	PaymentsSharedSetupPaymentStripeFieldsFragment,
} from './setupPaymentMethod.graphql';

// TODO utilityContext should be a separate parameter
type Params = PaymentsSharedSetupPaymentMethodMutationVariables & { utilityContext: UtilityContext };

export type SetupPaymentMethodSuccess = PaymentsSharedSetupPaymentMethodSuccessFieldsFragment;
export type DLocalCard = PaymentsSharedSetupPaymentDLocalFieldsFragment['card'];
export type StripeSetupIntent = PaymentsSharedSetupPaymentStripeFieldsFragment['setupIntent'];

export type StripeSetupFailure = Extract<
	PaymentsSharedSetupPaymentMethodMutation['setupPaymentMethod'],
	{ __typename: 'SetupPaymentMethodError' }
>;

// eslint-disable-next-line max-lines-per-function
export async function setupPaymentMethod({
	input: { gateway, token, usage, type, customer, dlocal, stripe },
	utilityContext,
}: Params): Promise<SetupPaymentMethodSuccess> {
	const {
		gql: { fetch },
	} = utilityContext;

	try {
		const { data, errors } = await fetch<
			PaymentsSharedSetupPaymentMethodMutation,
			PaymentsSharedSetupPaymentMethodMutationVariables
		>({
			query: gql`
				mutation PaymentsSharedSetupPaymentMethod($input: SetupPaymentMethodInput!) {
					setupPaymentMethod(input: $input) {
						__typename
						... on SetupPaymentMethodError {
							...SetupPaymentGatewayError
						}
						... on SetupPaymentMethodSuccess {
							...PaymentsSharedSetupPaymentMethodSuccessFields
						}
					}
				}
				fragment PaymentsSharedSetupPaymentMethodSuccessFields on SetupPaymentMethodSuccess {
					gatewayData {
						__typename

						... on SetupPaymentMethodStripeGatewayData {
							...PaymentsSharedSetupPaymentStripeFields
						}

						... on SetupPaymentMethodDLocalGatewayData {
							...PaymentsSharedSetupPaymentDLocalFields
						}
					}
				}
				fragment PaymentsSharedSetupPaymentStripeFields on SetupPaymentMethodStripeGatewayData {
					setupIntent {
						clientSecret
						accountId
						paymentMethodId
					}
				}
				fragment PaymentsSharedSetupPaymentDLocalFields on SetupPaymentMethodDLocalGatewayData {
					card {
						id
						accountId
					}
				}
				fragment SetupPaymentGatewayError on SetupPaymentMethodError {
					message
					type
					gatewayErrorData {
						code
						type
						declineCode
					}
				}
			`,
			variables: { input: { gateway, token, usage, type, customer, dlocal, stripe } },
			path: '/payments/setupPaymentMethod',
			important: true, // to ensure this rate limited mutation is not batched
		});
		if (errors) throw new Error(JSON.stringify(errors));
		if (!data) throw new Error('No data returned from mutation.');
		if (data.setupPaymentMethod.__typename === 'SetupPaymentMethodError') throw data.setupPaymentMethod;
		return data.setupPaymentMethod;
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(e as any).operationName = 'setup_payment_method';
		throw e;
	}
}

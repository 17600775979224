import { useI18n } from '@change/core/react/i18n';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { usePaymentTokenFunctions } from '../../../../hooks/usePaymentTokenFunctions';
import { useShowStripePaymentRequestButton } from '../../../../hooks/useShowStripePaymentRequestButton';
import { useMembershipModalState } from '../../../../modalContext';
import type { ModalCallbackProps } from '../../../../shared/types';

import { useTracking } from './hooks/useTracking';

type Result = ModelHookResult<
	{
		amount: number;
		currencyCode: string;
		label: string;
		paymentMethodSaveOptions: PaymentMethodSaveOptions;
		displayed: boolean;
		paymentType?: 'applePay' | 'googlePay';
		testId: string;
	},
	{
		beforeToken: (paymentType: PaymentType) => Promise<boolean>;
		onCancel: () => void;
		withToken: (params: WithTokenParams) => Promise<void>;
	}
>;

export function useStripePaymentRequestButton(modalCallbackProps: ModalCallbackProps): Result {
	const { amountToBaseUnits, translate } = useI18n();

	const [{ amount, currencyCode, paymentType }, { clearLoading }] = useMembershipModalState();

	const { beforeToken, withToken } = usePaymentTokenFunctions(modalCallbackProps);

	const { trackPaymentRequestCancel } = useTracking();

	const showStripePaymentRequestButton = useShowStripePaymentRequestButton();

	const isSupportedPaymentType = paymentType === 'applePay' || paymentType === 'googlePay';

	const data: Result['data'] = {
		amount: (amount && amountToBaseUnits(amount, currencyCode)) || 0,
		currencyCode,
		label: translate('fe.components.payment_request_button.line_item_description.monthly_membership'), // TODO: move this i18n key to corgi namespace
		paymentMethodSaveOptions: { shouldSavePaymentMethod: true, usage: 'OFF_SESSION' },
		displayed: showStripePaymentRequestButton,
		paymentType: isSupportedPaymentType ? paymentType : undefined,
		testId: paymentType === 'applePay' ? 'apple-pay-button' : 'google-pay-button',
	};

	return {
		data,
		actions: {
			beforeToken,
			onCancel: () => {
				clearLoading();
				trackPaymentRequestCancel();
			},
			withToken,
		},
	};
}

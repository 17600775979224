import { useCallback, useState } from 'react';
import type { ChangeEvent, JSX, PropsWithChildren } from 'react';

import { useI18n } from '@change/core/react/i18n';

import { useDLocal, useDLocalPermanentPaymentMethod } from 'src/app/shared/hooks/payments';
import { useSession } from 'src/app/shared/hooks/session';
import type { Field, SmartFieldEvent, TokenizationData } from 'src/app/shared/utils/payments';
import { getDlocalToken } from 'src/app/shared/utils/payments';

import { DLocalCreditCardForm } from './DLocalCreditCardForm';
import { DLocalSmartField } from './DLocalSmartField';
import type { DLocalValidationError } from './types';

type Props = {
	visible: boolean;
	beforeToken: () => void;
	onTokenError: (err: Error) => void;
	onTokenInvalid: (err: Error) => void;
	onSavePaymentError: (err: Error) => void;
	validate: () => boolean;
	withToken: (paymentType: string, token?: TokenizationData) => void;
	disabled: boolean;
	shouldSavePaymentMethod: boolean;
	nationalId: string;
	loading: boolean;
	onCardNumberFieldFocus: () => void;
	onCardExpiryFieldFocus: () => void;
	onCardCVCFieldFocus: () => void;
	onCardHolderNameFocus: () => void;
	fetchPermanentToken?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export function DLocalCreditCardElements({
	children,
	visible,
	beforeToken,
	onTokenError,
	onTokenInvalid,
	onSavePaymentError,
	validate,
	withToken,
	disabled,
	shouldSavePaymentMethod,
	nationalId,
	loading: parentLoading,
	onCardNumberFieldFocus,
	onCardExpiryFieldFocus,
	onCardCVCFieldFocus,
	onCardHolderNameFocus,
	fetchPermanentToken,
}: PropsWithChildren<Props>): JSX.Element {
	const { sdk } = useDLocal();
	const { translate } = useI18n();
	const session = useSession();
	const [pan, setPan] = useState<Field | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<DLocalValidationError>({ card: undefined, cvv: undefined, expire: undefined });
	const [cardHolder, setCardHolder] = useState<string | undefined>(session?.user?.displayName);
	const preparePermanentPaymentMethod = useDLocalPermanentPaymentMethod();

	const handleSubmit = useCallback(
		// eslint-disable-next-line max-statements
		async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
			event.preventDefault();
			beforeToken();
			if (!validate()) {
				return;
			}
			setLoading(true);
			const { token, error: dlocalError } = await getDlocalToken(cardHolder, pan, sdk);
			setLoading(false);
			if (dlocalError?.message) {
				onTokenError(dlocalError?.message as unknown as Error);
			}
			if (dlocalError?.error) {
				setError((errors) => ({
					...errors,
					card: translate('fe.errors.credit_card.number'),
				}));
				return;
			}
			if (!token) {
				onTokenInvalid(new Error('Failed to get a valid dLocal token'));
			}

			if (fetchPermanentToken || shouldSavePaymentMethod) {
				try {
					setLoading(true);
					const result = await preparePermanentPaymentMethod({
						token: token as string,
						tokenType: 'temporary',
						shouldSavePaymentMethod,
						usage: 'ON_SESSION',
						customer: {
							name: cardHolder as string,
							countryCode: session.country.countryCode,
							document: nationalId,
							email: session.user?.email as string,
						},
					});
					withToken('creditCard', result);
					setLoading(false);
				} catch (setupError) {
					setLoading(false);
					onSavePaymentError(setupError as Error);
				}
			} else {
				withToken('creditCard', {
					token: token as string,
					tokenType: 'temporary',
				});
			}
		},
		[
			beforeToken,
			validate,
			cardHolder,
			pan,
			sdk,
			fetchPermanentToken,
			shouldSavePaymentMethod,
			onTokenError,
			translate,
			onTokenInvalid,
			preparePermanentPaymentMethod,
			session.country.countryCode,
			session.user?.email,
			nationalId,
			onSavePaymentError,
			withToken,
		],
	);

	const onChangeCardHolderName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setCardHolder(event.target.value);
	}, []);

	const updateError = useCallback((field: string, event: SmartFieldEvent) => {
		const errorMessage = event.error ? event.error.message : undefined;
		setError((errors) => ({
			...errors,
			[field]: errorMessage,
		}));
	}, []);

	const cardNumberElement = useCallback(
		() => (
			<DLocalSmartField
				type="pan"
				onBlur={(event) => updateError('card', event)}
				onFocus={onCardNumberFieldFocus}
				onFieldCreated={setPan}
				placeholder="4111 1111 1111 1111"
			/>
		),
		[updateError, onCardNumberFieldFocus],
	);
	const cardExpiryElement = useCallback(
		() => (
			<DLocalSmartField
				type="expiration"
				onBlur={(event) => updateError('expire', event)}
				onFocus={onCardExpiryFieldFocus}
			/>
		),
		[updateError, onCardExpiryFieldFocus],
	);
	const cardCVVElement = useCallback(
		() => <DLocalSmartField type="cvv" onBlur={(event) => updateError('cvv', event)} onFocus={onCardCVCFieldFocus} />,
		[updateError, onCardCVCFieldFocus],
	);
	return (
		<DLocalCreditCardForm
			visible={visible}
			handleSubmit={handleSubmit}
			cardHolder={cardHolder}
			onChangeCardHolderName={onChangeCardHolderName}
			error={error}
			cardNumberElement={cardNumberElement}
			cardExpiryElement={cardExpiryElement}
			cardCVVElement={cardCVVElement}
			disabled={disabled || loading}
			loading={parentLoading || loading}
			onCardHolderNameFocus={onCardHolderNameFocus}
		>
			{children}
		</DLocalCreditCardForm>
	);
}

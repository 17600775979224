import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

export function Footer(): JSX.Element {
	return (
		<Box sx={{ textAlign: 'center', font: 'body' }}>
			<Text size="caption" data-qa="ppmu-modal-footer-text">
				<Translate value="corgi.co.ppmu.modal.footer_text" />
			</Text>
		</Box>
	);
}

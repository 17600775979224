import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Image } from '@change/design-system/components/media';
import { Box } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

import { useMembershipModalState } from '../modalContext/modalState';

export function Header(): JSX.Element {
	const [{ useDonationLanguage }] = useMembershipModalState();

	const getSuffix = () => {
		return useDonationLanguage ? '_donation' : '';
	};

	return (
		<>
			<Box sx={{ mb: 16 }}>
				<Box
					sx={{
						float: 'right',
						overflow: 'hidden',
						width: 120,
						height: 120,
					}}
				>
					<Image
						src="https://static.change.org/iconography/Icon_megaphone.png"
						alt="shout out"
						sx={{ maxWidth: '150%', position: 'relative', right: 30, bottom: 30 }}
						data-qa="ppmu-modal-header-image"
					/>
				</Box>
				<Heading size="h3" data-qa="ppmu-modal-header-title">
					<Translate value={`corgi.co.ppmu.modal.title${getSuffix()}`} />
				</Heading>
			</Box>
			<Text data-qa="ppmu-modal-header-description">
				<Translate value={`corgi.co.ppmu.modal.description${getSuffix()}`} />
			</Text>
		</>
	);
}

import type { MouseEvent, PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Link } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { iconExpandLess, iconExpandMore } from '@change/design-system/icons';
import { Box } from '@change/design-system/layout';

export type ExpanderProps = {
	onExpand?: () => void;
	onCollapse?: () => void;
};

export function Expander({ children, onExpand, onCollapse }: PropsWithChildren<ExpanderProps>): React.JSX.Element {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const toggleIsExpanded = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setIsExpanded((wasExpanded) => {
				if (wasExpanded && onCollapse) onCollapse();
				if (!wasExpanded && onExpand) onExpand();
				return !wasExpanded;
			});
		},
		[onExpand, onCollapse],
	);

	return (
		<>
			<Link
				to=""
				onClick={toggleIsExpanded}
				type="button"
				data-qa="promote-ad-preview-link"
				data-testid="promote-ad-preview-link"
			>
				<Translate value="corgi.co.promote.preview_ad" />{' '}
				<Box as="span" mr={4} sx={{ verticalAlign: 'middle' }}>
					{isExpanded ? (
						<Icon
							icon={iconExpandLess}
							data-qa="promote-ad-preview-icon-bracket-up"
							data-testid="icon-bracket-up"
							size="s"
						/>
					) : (
						<Icon
							icon={iconExpandMore}
							data-qa="promote-ad-preview-icon-bracket-down"
							data-testid="icon-bracket-down"
							size="s"
						/>
					)}
				</Box>
			</Link>
			{isExpanded && children}
		</>
	);
}

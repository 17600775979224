import type React from 'react';
import type { ChangeEventHandler } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeError, StripeIbanElementChangeEvent } from '@stripe/stripe-js';

import type { TranslationKey } from '@change/core/i18n';
import { useI18n } from '@change/core/react/i18n';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useCurrentUserCurrency } from 'src/app/shared/hooks/session';
import type { WithTokenParams } from 'src/app/shared/types';
import { isLoaded } from 'src/app/shared/utils/async';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';
import {
	confirmStripeSepaDebitSetup,
	saveStripePaymentMethod,
	setupStripePaymentMethod,
	stripeErrorI18nKey,
	stripeInputValidationError,
} from 'src/app/shared/utils/payments';

import { createStripeSepaDebitPayment } from '../../util/createStripeSepaDebitPayment';

type Params = {
	name: string;
	email: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
};

type Result = ModelHookResult<
	{
		errorMessage?: string | null;
		validationError?: string | null;
	},
	{
		handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
		validateStripeElementOnChange: ChangeEventHandler<HTMLInputElement>;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useIbanForm({
	name,
	email,
	paymentMethodSaveOptions,
	beforeToken,
	validate,
	prePaymentChecks,
	onTokenError,
	onTokenInvalid,
	withToken,
}: Params): Result {
	const stripe = useStripe();
	const elements = useElements();
	const countryCode = useCountryCode();
	const currentUserCurrencyState = useCurrentUserCurrency();
	const utilityContext = useUtilityContext();
	const { translate } = useI18n();
	const [validationError, setValidationError] = useState<TranslationKey | undefined | null>();
	const [errorMessage, setErrorMessage] = useState<TranslationKey | null>();

	const currency = useMemo(() => {
		if (isLoaded(currentUserCurrencyState)) return currentUserCurrencyState.currency.code;
		return null;
	}, [currentUserCurrencyState]);

	const onStripeError = useCallback(
		(error: StripeError) => {
			const inputValidationError = stripeInputValidationError(error);

			if (inputValidationError?.account_number) {
				setValidationError(inputValidationError.account_number);
			} else {
				const message = stripeErrorI18nKey(error);
				setErrorMessage(message);
			}

			onTokenInvalid('sepa', new Error(error.message || 'Unhandled Error'));
		},
		[onTokenInvalid],
	);

	const validateStripeElementOnChange = useCallback((e: StripeIbanElementChangeEvent) => {
		setValidationError(e.error ? stripeErrorI18nKey(e.error) : null);
	}, []);

	const handleSubmit = useCallback(
		// eslint-disable-next-line max-statements, complexity
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			await beforeToken('sepa');
			if (!(await validate('sepa')) || !(await prePaymentChecks('sepa'))) return;
			try {
				if (!stripe || !elements) throw new Error('Failed to load Stripe library');
				const createResult = await createStripeSepaDebitPayment(stripe, elements, name, email);
				if (createResult.error) {
					onStripeError(createResult.error);
					return;
				}
				if (paymentMethodSaveOptions.shouldSavePaymentMethod) {
					const { accountId, clientSecret } = await setupStripePaymentMethod(
						createResult.paymentMethod,
						'OFF_SESSION',
						utilityContext,
						'sepa',
						countryCode,
						currency,
					);

					const confirmError = await confirmStripeSepaDebitSetup(stripe, createResult.paymentMethod, clientSecret);
					if (confirmError) {
						onStripeError(confirmError);
						return;
					}

					await saveStripePaymentMethod(
						createResult.paymentMethod.id,
						email,
						'OFF_SESSION',
						utilityContext,
						'sepa',
						accountId ?? undefined,
					);
				}
				void withToken({ paymentType: 'sepa', token: createResult.paymentMethod.id });
			} catch (error) {
				onTokenError('sepa', error as Error);
			}
		},
		[
			beforeToken,
			countryCode,
			currency,
			elements,
			email,
			name,
			onStripeError,
			onTokenError,
			paymentMethodSaveOptions.shouldSavePaymentMethod,
			prePaymentChecks,
			stripe,
			utilityContext,
			validate,
			withToken,
		],
	);

	return {
		data: {
			validationError: validationError && translate(validationError),
			errorMessage: errorMessage && translate(errorMessage),
		},
		actions: {
			handleSubmit,
			validateStripeElementOnChange: validateStripeElementOnChange as unknown as ChangeEventHandler<HTMLInputElement>,
		},
	};
}

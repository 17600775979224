import { cloneElement } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { FormError, Input } from '@change/design-system/components/forms';
import { Box, Flex } from '@change/design-system/layout';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import type { SubmitButtonProps } from '../../../index';

import { IbanElementWrapper } from './components/IbanElementWrapper';
import { useIbanForm } from './hooks/useIbanForm';

type Props = {
	name: string;
	email: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	onFieldFocus: () => void;
	submitButton: React.ReactElement<SubmitButtonProps>;
};

export function IbanForm({
	name,
	email,
	paymentMethodSaveOptions,
	beforeToken,
	validate,
	prePaymentChecks,
	onTokenError,
	onTokenInvalid,
	withToken,
	onFieldFocus,
	submitButton,
}: Props): React.JSX.Element | null {
	const { translate } = useI18n();
	const {
		data: { validationError, errorMessage },
		actions: { handleSubmit, validateStripeElementOnChange },
	} = useIbanForm({
		name,
		email,
		paymentMethodSaveOptions,
		beforeToken,
		validate,
		prePaymentChecks,
		onTokenError,
		onTokenInvalid,
		withToken,
	});

	return (
		<>
			<Box mb={16}>
				<form data-testid="sepa-form" onSubmit={handleSubmit}>
					<Box mb={24}>
						<Input
							// eslint-disable-next-line react/forbid-component-props
							className="iframe-form-element"
							// TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.

							data-qa="sepa-iframe-form-element"
							label={translate('fe.components.sepa.account_number')}
							name="account_number"
							error={validationError}
							as={IbanElementWrapper}
							onFocus={onFieldFocus}
							onChange={validateStripeElementOnChange}
							id="sepa-account-number"
						/>
					</Box>
					<Flex
						sx={{
							justifyContent: 'center',
						}}
					>
						{cloneElement(submitButton, {
							disabled: submitButton.props.disabled,
						})}
					</Flex>
				</form>
			</Box>
			{errorMessage && (
				<FormError mb={16} data-qa="iban-form-error-message">
					{errorMessage}
				</FormError>
			)}
		</>
	);
}

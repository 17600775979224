import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useUtilityContext } from '@change/core/react/utilityContext';

import stripeConfig from 'config/stripe.json';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

export function useLoadStripe(): (stripe: Stripe | null, accountId?: string | null) => Promise<Stripe> {
	const utilityContext = useUtilityContext();
	const countryCode = useCountryCode();
	type StripeTokenizationAccountIdConfig = Record<
		string,
		{ tokenization_account: { id: string | undefined }; betas?: string[] }
	>;

	return async function loadStripeWrapper(stripe: Stripe | null, accountId?: string | null): Promise<Stripe> {
		const createPaymentMethodConfig: StripeTokenizationAccountIdConfig = stripeConfig.create_payment_method_config;

		const tokenizationId = createPaymentMethodConfig[countryCode]?.tokenization_account.id;

		// if accountId is not changed and we already have stripe instance then return it
		if (stripe && tokenizationId === accountId) return stripe;

		// otherwise reload it
		const environment = utilityContext.environment.getEnvironment();
		const stripeInstance = accountId
			? await loadStripe(stripeConfig.key[environment], { stripeAccount: accountId })
			: stripe;
		if (!stripeInstance) throw new Error('Stripe instance not found');
		return stripeInstance;
	};
}

import { GQLResponsePerimeterXChallengeError } from '@change/core/gql';
import { translationKey } from '@change/core/i18n';
import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';

import { useRefreshSession } from 'src/app/shared/hooks/session';
import type { WithTokenParams } from 'src/app/shared/types';
import { isStripeError, stripeErrorI18nKey } from 'src/app/shared/utils/payments';

import { useCreateMembership } from '../../../hooks/useCreateMembership';
import { usePreparePaymentMethod } from '../../../hooks/usePreparePaymentMethod';
import { useMembershipModalState } from '../../../modalContext';
import type { ModalCallbackProps } from '../../../shared/types';

import { isUserAlreadyACustomerError } from './utils/isUserAlreadyACustomerError';

type SubmitParams = Omit<WithTokenParams, 'paymentType' | 'cardType'>;

export function useSubmit({ onAccept, onDecline }: ModalCallbackProps): (params?: SubmitParams) => Promise<void> {
	const track = useTracking();
	const { translate } = useI18n();

	const [{ acceptMembership }, { setLoading, clearLoading, setGeneralErrorMessage }] = useMembershipModalState();
	const preparePaymentMethod = usePreparePaymentMethod();
	const createMembership = useCreateMembership();
	const refreshSession = useRefreshSession();

	// eslint-disable-next-line max-statements
	return async function submit(params) {
		if (!acceptMembership) {
			void track('post_promote_member_offer_submit_decline');
			onDecline();
			return;
		}
		void track('post_promote_member_offer_submit');

		setLoading();
		try {
			await preparePaymentMethod(params?.token);
			await createMembership(params?.token);
			void track('subs_modal_success', {
				source_location: 'post_promote',
			});
			void track('post_promote_offer_success');
			clearLoading();
			void refreshSession();
			params?.onTransactionComplete?.('success');
			onAccept();
		} catch (error) {
			if (isUserAlreadyACustomerError(error)) {
				onDecline();
				return;
			}
			/*
				If PerimeterX error is thrown in PPMU flow, we are not able to currently display the challenge modal
				on top of PPMU modal. For now, it tracks the error event, closes the modal and takes users to promote thank you page
				*/
			if (error instanceof GQLResponsePerimeterXChallengeError) {
				void track('post_promote_member_failure', {
					source_location: 'post_promote',
					errorMessage: 'Human verification required',
				});
				onDecline();
			} else void track('post_promote_member_failure');
			params?.onTransactionComplete?.('fail');
			const errorMessageTranslationKey = isStripeError(error) ? stripeErrorI18nKey(error) : undefined;
			setGeneralErrorMessage(translate(errorMessageTranslationKey ?? translationKey('fe.errors.generic_try_again')));
		}
	};
}

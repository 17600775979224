import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	PaymentsSharedSavePaymentMethodMutation,
	PaymentsSharedSavePaymentMethodMutationVariables,
} from './savePaymentMethod.graphql';

// TODO utilityContext should be a separate parameter
type Params = PaymentsSharedSavePaymentMethodMutationVariables & { utilityContext: UtilityContext };

// eslint-disable-next-line max-lines-per-function
export async function savePaymentMethod({
	input: {
		customer: { email },
		gateway,
		token,
		type,
		usage,
		gatewayData,
	},
	utilityContext,
}: Params): Promise<void> {
	const {
		gql: { fetch },
	} = utilityContext;
	try {
		const { data, errors } = await fetch<
			PaymentsSharedSavePaymentMethodMutation,
			PaymentsSharedSavePaymentMethodMutationVariables
		>({
			query: gql`
				mutation PaymentsSharedSavePaymentMethod($input: SavePaymentMethodInput!) {
					savePaymentMethod(input: $input) {
						__typename
						... on SavePaymentMethodError {
							message
							type
							declineCode
						}
					}
				}
			`,
			variables: {
				input: {
					customer: { email },
					gateway,
					token,
					type,
					usage,
					gatewayData,
				},
			},
			path: '/payments/savePaymentMethod',
			important: true, // to ensure this rate limited mutation is not batched
		});

		if (errors) throw new Error(JSON.stringify(errors));
		if (!data) throw new Error('No data returned from mutation.');
		if (data.savePaymentMethod.__typename === 'SavePaymentMethodError') throw data.savePaymentMethod;
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(e as any).operationName = 'save_payment_method';
		throw e;
	}
}

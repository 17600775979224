import { useCallback, useEffect } from 'react';

import { useTracking } from '@change/core/react/tracking';

import type { ModalCallbackProps } from '../../../shared/types';

export function useUserEventListeners({ onDecline }: ModalCallbackProps): void {
	const track = useTracking();

	const escapeHandler = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				void track('post_promote_member_offer_submit_escape');
				onDecline();
			}
		},
		[onDecline, track],
	);

	const browserBackButtonHandler = useCallback(() => {
		void track('post_promote_member_offer_submit_browser_back_button');
		onDecline();
	}, [onDecline, track]);

	const beforeUnloadHandler = useCallback(() => {
		void track('post_promote_member_offer_submit_unload');
	}, [track]);

	useEffect(() => {
		document.addEventListener('keydown', escapeHandler);
		// The back button should dismiss the modal rather than navigate backward to ensure
		// payment form elements remain mounted on the page to complete the transaction.
		window.history.pushState(null, '');
		window.addEventListener('popstate', browserBackButtonHandler, { once: true });
		window.addEventListener('beforeunload', beforeUnloadHandler);

		return () => {
			document.removeEventListener('keydown', escapeHandler);
			window.removeEventListener('popstate', browserBackButtonHandler);
			window.removeEventListener('beforeunload', beforeUnloadHandler);
		};
	}, [escapeHandler, browserBackButtonHandler, beforeUnloadHandler]);
}

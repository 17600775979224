import type { StripeError } from '@stripe/stripe-js';

import type { TranslationKey } from '@change/core/i18n';

import { stripeErrorI18nKey } from './stripeErrorI18nKey';
import { stripeInputName } from './stripeInputName';
import type { StripeInputNames } from './types/StripeInputNames';

export const DEFAULT_ERROR_VALIDATION = {
	card_number: null,
	card_expiration: null,
	card_cvc: null,
	account_number: null,
};

export type ValidationErrors = Record<StripeInputNames, TranslationKey | null>;

export function stripeInputValidationError(error: StripeError): ValidationErrors | undefined {
	const inputName = stripeInputName(error.code);
	const i18nKey = stripeErrorI18nKey(error);

	if (!inputName || !i18nKey) return undefined;

	return {
		...DEFAULT_ERROR_VALIDATION,
		[inputName]: i18nKey,
	};
}

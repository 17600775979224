import type { UtilityContext } from '@change/core/react/utilityContext';

import { savePaymentMethod } from 'src/app/shared/api/payments';

import { gqlPaymentType } from '../../gqlPaymentType';
import type { PaymentType } from '../../types';

// eslint-disable-next-line max-params
export async function saveStripePaymentMethod(
	token: string,
	email: string,
	usage: 'OFF_SESSION' | 'ON_SESSION',
	utilityContext: UtilityContext,
	paymentType: PaymentType,
	accountId?: string,
): Promise<void> {
	await savePaymentMethod({
		input: {
			customer: { email },
			gateway: 'STRIPE',
			usage,
			token,
			type: gqlPaymentType(paymentType),
			gatewayData: accountId ? { accountId } : null,
		},
		utilityContext,
	});
}

import type { JSX } from 'react';

import { FormError } from '@change/design-system/components/forms';

import { useMembershipModalState } from '../modalContext';

export function GeneralErrorMessage(): JSX.Element | null {
	const [{ formState }] = useMembershipModalState();
	if (formState?.status !== 'error') return null;
	return (
		<FormError data-testid="form-error" mb={16}>
			{formState.error}
		</FormError>
	);
}

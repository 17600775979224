import { useI18n } from '@change/core/react/i18n';

import type { NewPaymentMethodButtonProps } from '../../index.tsx';

type Result = ModelHookResult<
	{
		label: string;
		detailText: string;
		logoSrc: string;
		selected: boolean;
		type: string;
	},
	{
		setNewSelectedPaymentType: () => void;
	}
>;

export function useNewPaymentMethodButton({
	usingSavedPaymentMethod,
	availableNewPaymentMethods,
	onSelectNewPaymentMethod,
}: NewPaymentMethodButtonProps): Result {
	const { translate } = useI18n();

	const selected = !usingSavedPaymentMethod;

	const DEFAULT_NEW_PAYMENT_METHOD = availableNewPaymentMethods[0] || 'creditCard';

	return {
		data: {
			label: `+ ${translate('fe.pages.sponsors.new_payment_method')}`, // TODO: Move to corgi namespace
			detailText: '',
			logoSrc: 'https://static.change.org/payment-options/btn/credit-card-new.svg',
			selected,
			type: 'new',
		},
		actions: {
			setNewSelectedPaymentType: () => {
				onSelectNewPaymentMethod(DEFAULT_NEW_PAYMENT_METHOD);
			},
		},
	};
}

import { amountFromBaseUnits } from '@change/core/intl';

type SubscriptionAmounts = {
	amountChoices: number[];
	minimumAmount: number;
	maximumAmount: number;
	defaultAmount: number;
};

type Params = {
	subscriptionConfiguration: {
		amounts: readonly number[];
		defaultAmount: number;
		minAmount: number;
		maxAmount: number;
	};
	currency: string;
};

export function getSubscriptionAmounts({ subscriptionConfiguration, currency }: Params): SubscriptionAmounts {
	return {
		amountChoices: subscriptionConfiguration.amounts.map((amount) => amountFromBaseUnits(amount, currency)),
		minimumAmount: amountFromBaseUnits(subscriptionConfiguration.minAmount, currency),
		maximumAmount: amountFromBaseUnits(subscriptionConfiguration.maxAmount, currency),
		defaultAmount: amountFromBaseUnits(subscriptionConfiguration.defaultAmount, currency),
	};
}

/* eslint-disable max-lines-per-function */
import type { PropsWithChildren } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router';

import { Translate } from '@change/core/react/i18n';
import { FakeLink } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { iconInfo } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePaypal } from 'src/app/shared/hooks/payments';
import { isPaypalSdkLoadError } from 'src/app/shared/utils/payments';

import type { NewPaymentMethodButtonProps } from './components/NewPaymentMethodButton';
import { NewPaymentMethodButton } from './components/NewPaymentMethodButton';
import type { NewPaymentMethodPaypalProps } from './components/NewPaymentMethodPaypal';
import { NewPaymentMethodPaypal } from './components/NewPaymentMethodPaypal';
import type { NewPaymentMethodSepaProps } from './components/NewPaymentMethodSepa';
import { NewPaymentMethodSepa } from './components/NewPaymentMethodSepa';
import type { NewPaymentMethodStripeProps } from './components/NewPaymentMethodStripe';
import { NewPaymentMethodStripe } from './components/NewPaymentMethodStripe';
import type { NewPaymentMethodUpiProps } from './components/NewPaymentMethodUpi';
import { NewPaymentMethodUpi } from './components/NewPaymentMethodUpi';
import type { NewStripePaymentRequestButtonProps } from './components/NewStripePaymentRequestButton';
import { NewStripePaymentRequestButton } from './components/NewStripePaymentRequestButton';
import { PaymentTypeButton } from './components/PaymentTypeButton';
import type { SavedPaymentMethodButtonProps } from './components/SavedPaymentMethodButton';
import { SavedPaymentMethodButton } from './components/SavedPaymentMethodButton';
import { usePaymentMethodSelector } from './hooks/usePaymentMethodSelector';
import type { SavedPaymentMethod } from './types';

type PaymentMethodSelectorProps = NewPaymentMethodButtonProps &
	NewPaymentMethodPaypalProps &
	NewPaymentMethodSepaProps &
	NewPaymentMethodStripeProps &
	NewPaymentMethodUpiProps &
	NewStripePaymentRequestButtonProps &
	Omit<SavedPaymentMethodButtonProps, 'paymentMethod'> & {
		savedPaymentMethods: SavedPaymentMethod[] | undefined;
	};

// eslint-disable-next-line complexity
export function PaymentMethodSelector({
	savedPaymentMethods,
	selectedPaymentType,
	usingSavedPaymentMethod,
	availableNewPaymentMethods,
	validate,
	beforeToken,
	onTokenError,
	onTokenInvalid,
	withToken,
	prePaymentChecks,
	currencyCode,
	email,
	name,
	vpa,
	validationErrors,
	setVpa,
	paymentMethodSaveOptions,
	ask,
	amount,
	clearLoading,
	loading,
	validationResult,
	useDonationLanguage,
	onSelectSavedPaymentMethod,
	onSelectNewPaymentMethod,
	onFieldFocusIban,
	onFieldFocusUpi,
	countryStateSelect,
	submitButton,
	phoneNumberInput,
	transactionFeeToggle,
	children,
}: PropsWithChildren<PaymentMethodSelectorProps>): React.JSX.Element {
	const {
		data: {
			showNewPaymentMethodButtons,
			showNewPaypalPaymentMethod,
			showNewStripePaymentMethod,
			showNewStripePaymentRequestButton,
			showNewSepaPaymentMethod,
			showSavedPaymentMethodButtons,
			showNewUpiPaymentMethod,
		},
	} = usePaymentMethodSelector({
		savedPaymentMethods,
		selectedPaymentType,
		usingSavedPaymentMethod,
		availableNewPaymentMethods,
	});

	const paypalState = usePaypal();
	const isPaypalError = isPaypalSdkLoadError({ paypalState, availableNewPaymentMethods });
	const navigate = useNavigate();

	return (
		<Box>
			{availableNewPaymentMethods.length > 1 && (
				<Heading my={8} as="h2" size="h5">
					<Translate value="fe.components.credit_card.payment_method" /> {/* TODO: move key to corgi namespace */}
				</Heading>
			)}
			{showSavedPaymentMethodButtons &&
				savedPaymentMethods?.map((paymentMethod) => (
					<SavedPaymentMethodButton
						key={paymentMethod.type}
						paymentMethod={paymentMethod}
						selectedPaymentType={selectedPaymentType}
						usingSavedPaymentMethod={usingSavedPaymentMethod}
						onSelectSavedPaymentMethod={onSelectSavedPaymentMethod}
					/>
				))}
			{showSavedPaymentMethodButtons && (
				<NewPaymentMethodButton
					usingSavedPaymentMethod={usingSavedPaymentMethod}
					availableNewPaymentMethods={availableNewPaymentMethods}
					onSelectNewPaymentMethod={onSelectNewPaymentMethod}
				/>
			)}
			{showNewPaymentMethodButtons && (
				<Flex sx={{ gap: '10px' }} mb={16}>
					{availableNewPaymentMethods.map((paymentType) => (
						<Flex sx={{ flex: 1 }} key={paymentType}>
							<PaymentTypeButton
								type={paymentType}
								selectedPaymentType={selectedPaymentType}
								onSelectNewPaymentMethod={onSelectNewPaymentMethod}
								disabled={paymentType === 'paypal' && isPaypalError}
							/>
						</Flex>
					))}
				</Flex>
			)}
			{showNewPaymentMethodButtons && isPaypalError && (
				<Flex
					my={16}
					sx={{
						fontSize: [10, 12],
						justifyContent: 'center',
						lineHeight: '18px',
						flexDirection: 'row',
					}}
				>
					<Icon icon={iconInfo} size={16} mr={8} />
					<Translate value="corgi.payments.paypal_sdk_not_loaded" />
					&nbsp;
					<FakeLink
						data-qa="paypal-error-retry-link"
						sx={{ display: 'contents', color: 'secondary-blue', fontWeight: 'normal', fontSize: 'caption' }}
						onClick={() => navigate(0)}
					>
						<Translate value="corgi.payments.click_to_try_again" />
					</FakeLink>
				</Flex>
			)}

			{children}

			{!showNewStripePaymentMethod && (
				<>
					<Box>{phoneNumberInput}</Box>
					<Box>{transactionFeeToggle}</Box>
				</>
			)}
			<Box sx={{ display: showNewStripePaymentMethod ? 'block' : 'none' }}>
				<NewPaymentMethodStripe
					email={email}
					name={name}
					paymentMethodSaveOptions={paymentMethodSaveOptions}
					beforeToken={beforeToken}
					onTokenError={onTokenError}
					onTokenInvalid={onTokenInvalid}
					withToken={withToken}
					validate={validate}
					prePaymentChecks={prePaymentChecks}
					submitButton={submitButton}
					countryStateSelect={countryStateSelect}
					phoneNumberInput={phoneNumberInput}
					transactionFeeToggle={transactionFeeToggle}
				/>
			</Box>

			<Box
				mx="auto"
				sx={{ width: ['100%', '58%'], display: showNewPaypalPaymentMethod ? 'block' : 'none' }}
				data-testid="paypal-button" // TODO: replace data-testid with data-qa when the regression suite is updated
			>
				<NewPaymentMethodPaypal
					currencyCode={currencyCode}
					amount={amount}
					loading={loading}
					email={email}
					paymentMethodSaveOptions={paymentMethodSaveOptions}
					useDonationLanguage={useDonationLanguage}
					validationResult={validationResult}
					validate={validate}
					beforeToken={beforeToken}
					onTokenError={onTokenError}
					onTokenInvalid={onTokenInvalid}
					withToken={withToken}
					prePaymentChecks={prePaymentChecks}
				/>
			</Box>

			<Box
				mx="auto"
				sx={{ width: ['100%', '58%'], display: showNewStripePaymentRequestButton ? 'block' : 'none' }}
				data-testid="apple-pay-button" // TODO: replace data-testid with data-qa when the regression suite is updated
			>
				<NewStripePaymentRequestButton
					currencyCode={currencyCode}
					ask={ask}
					amount={amount}
					selectedPaymentType={selectedPaymentType}
					clearLoading={clearLoading}
					beforeToken={beforeToken}
					withToken={withToken}
					validate={validate}
					prePaymentChecks={prePaymentChecks}
				/>
			</Box>

			<Box sx={{ display: showNewSepaPaymentMethod ? 'block' : 'none' }}>
				<NewPaymentMethodSepa
					email={email}
					name={name}
					paymentMethodSaveOptions={paymentMethodSaveOptions}
					validate={validate}
					beforeToken={beforeToken}
					onTokenError={onTokenError}
					onTokenInvalid={onTokenInvalid}
					withToken={withToken}
					prePaymentChecks={prePaymentChecks}
					onFieldFocusIban={onFieldFocusIban}
					submitButton={submitButton}
				/>
			</Box>

			<Box sx={{ display: showNewUpiPaymentMethod ? 'block' : 'none' }}>
				<NewPaymentMethodUpi
					email={email}
					name={name}
					vpa={vpa}
					validationErrors={validationErrors}
					setVpa={setVpa}
					validate={validate}
					beforeToken={beforeToken}
					onTokenError={onTokenError}
					onTokenInvalid={onTokenInvalid}
					withToken={withToken}
					prePaymentChecks={prePaymentChecks}
					onFieldFocusUpi={onFieldFocusUpi}
					submitButton={submitButton}
					countryStateSelect={countryStateSelect}
				/>
			</Box>

			{!showNewPaymentMethodButtons && <Box>{countryStateSelect}</Box>}
		</Box>
	);
}
